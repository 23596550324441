body {
    background: linear-gradient(180deg, #514C47 0%, #2D2B2A 19.14%, #151515 39.65%, #0E0E0E 60.66%, #000000 100%);
    background-size: cover;
    overflow: hidden; 
    color: white;
    height: 100vh;
}

.main-container {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1rem;
    height: 100%;
}

.content-div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    justify-self: center;
    max-width: 700px;
    padding: 5vw;
    position: relative;
    z-index: 1;
}

.content-div::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 17.19%, rgba(75, 69, 61, 0.132) 40.03%, rgba(199, 182, 159, 0.06) 54.24%, rgba(199, 182, 159, 0.12) 80.44%, rgba(115, 115, 115, 0.022) 10%);
    clip-path: polygon(25% 100%, 75% 100%, 100% 0, 0 0);
    filter: blur(65px);
    z-index: -1;
}



.app-logo {
    margin-bottom: 2rem;
    filter: drop-shadow(100px 100px 100px #0D071C12);
    border-radius: 20px;
}

.title-text {
    font-family: "Martel Sans", sans-serif;
    font-size: 34px;
    font-weight: 800;
    line-height: 46px;
    letter-spacing: -1.5839999914169312px;
    text-align: center;
}


.description-div{
    margin-top: 2rem;
    text-align: center;
    line-height: 1.2em;
    max-height: 2.4em; 
    overflow: hidden;
    font-family: "Martel Sans", sans-serif;
    font-size: 15px;
    font-weight: 600;
    text-align: center;
    margin-bottom: 2rem;
}

.second-description-div {
    margin-top: 2rem;
    font-family: "Martel Sans", sans-serif;
    font-size: 18px;
    font-weight: 600;
    line-height: 25px;
    letter-spacing: 0em;
    text-align: center;
}


.links-div {
    margin-top: 2rem;
    display: flex;
    gap: 30px;
    justify-content: center;
}

.links-div a {
    font-family: "Martel Sans", sans-serif;
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: center;
    color: white;
    opacity: 0.5;
}

.footer {
    margin-top: 1.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.footer span {
    font-family: Manrope;
    font-size: 11px;
    font-weight: 500;
    line-height: 15px;
    letter-spacing: 0.01em;
    text-align: center;
    /* color: #FFFFFF80; */
    color: #FFFFFF40;

}


/* Media query for phones */
@media only screen and (max-width: 600px) {

    body {
        overflow: hidden;
        height: 100vh;
    }


    .content-div::before {
        height: 100vh;
    }

    .description-div {
        max-height: 4.8em;
    }

    .app-logo {
        width: 35vw!important;
    }

    .appstore-logo {
        width: 35vw!important;
    }

    .links-div {
        display: flex;
        flex-direction: column;
    }

    .links-div a {
        color: #FFFFFF;
        font-size: 3vw;
        opacity: 0.5;
    }
    
}

.title-text {
    font-family: "Martel Sans", sans-serif;
    letter-spacing: 0.05rem;
    text-transform: uppercase;
    font-size: 1rem;
    font-weight: 300;
    line-height: 27.36px;
    text-align: center;
    color: #AAAAAA;

}

.title-header {
    font-size: 2rem;
    font-family: "Philosopher", sans-serif;;
    font-weight: 700;
    line-height: 35.84px;
    text-align: center;
    background: linear-gradient(85.22deg, #FFF9EF 16.09%, #BDAA92 64.26%);
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
}


p.p1 {margin: 0.0px 0.0px 0.0px 0.0px; font: 13.0px 'Helvetica Neue'}
p.p2 {margin: 0.0px 0.0px 0.0px 0.0px; font: 13.0px 'Helvetica Neue'; min-height: 15.0px}


body.terms-privacy-body {
    height: 100%;
    overflow-y: auto;
}
